<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader :title="campaign.title" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="mt-4" />
    <DonationsList v-else :campaign="campaign" :striped="true" :hover="true" />
  </div>
</template>

<script>
import DonationsList from 'components/donations/DonationsList';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';

export default {
  name: 'CampaignDonationsIndex',
  components: {
    DonationsList,
    Error,
    Loading,
    PageHeader,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      // console.log('CampaignDonationsIndex methods fetchData() started');
      this.error = null;
      this.isLoading = true;

      const payload = {
        id: this.id,
        params: {},
      };
      // console.log('CampaignDonationsIndex methods fetchData() before', payload);
      this.$store
        .dispatch('campaigns/get', payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
